/*
 * Filter
 */

import { createContext, useContext } from 'react';

export const FilterContext = createContext();

export const FilterProvider = FilterContext.Provider;

export const useFilter = () => {
    return useContext(FilterContext);
}

export const FilterInvoice = (invoice) => {
    const filterContext = useFilter(),
          filters = filterContext.filters;

    return invoiceFilter(invoice, filters);
};

export const invoiceFilter = (invoice, filters) => {
    let isIncluded = true,
        filterList = ["invoiceDate", "paid"];

    filterList.forEach(filter => {
        if (isIncluded) {
            switch (filter) {
                case "invoiceDate":
                    isIncluded = filterDate(invoice.deadline, filters.invoiceDate);
                    break;
                case "paid":
                    isIncluded = filterPaid(invoice.paid, filters.paid);
                    break;
                default:
                    break;
            }
        }
    });

    return isIncluded;
};

const filterDate = (deadline, filterInvoiceDate) => {
    const invoiceDate = new Date(deadline),
          invoiceMonth = invoiceDate.getMonth(),
          invoiceYear = invoiceDate.getFullYear(),
          filteredMonth = filterInvoiceDate.getMonth(),
          filteredYear = filterInvoiceDate.getFullYear();

    return (filteredMonth === invoiceMonth) && (filteredYear === invoiceYear);
};

const filterPaid = (paid, filterPaid) => {
    return filterPaid === (paid !== null);
};