/*
 * Selector
 */

import React, { useState } from 'react';
import { TextField, IconButton, ButtonGroup } from '@material-ui/core';
import { useFilter } from "components/filter/FilterInvoice";
import getIcon from 'models/icon/Icon';
import useStyles from './Styles';

const Selector = () => {
    const filterContext = useFilter(),
          filters = filterContext.filters,
          setFilters = filterContext.setFilters,
          today = new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)),
          limit = 12,
          classes = useStyles();

    const [invoiceDate, setInvoiceDate] = useState(filters.invoiceDate);

    const previous = () => {
        let checkPast = new Date(invoiceDate);

        checkPast.setMonth(checkPast.getMonth() - 1);
    
        let pastStop = new Date(new Date(new Date(today).setMonth(today.getMonth() - limit)).setHours(0, 0, 0, 0));

        pastStop <= checkPast && change(new Date(invoiceDate.setMonth(invoiceDate.getMonth() - 1)));
    }

    const next = () => {
        let checkFuture = new Date(invoiceDate);

        checkFuture.setMonth(checkFuture.getMonth() + 1);

        let futureStop = new Date(new Date(new Date(today).setMonth(today.getMonth() + limit)).setHours(0, 0, 0, 0));

        futureStop >= checkFuture && change(new Date(invoiceDate.setMonth(invoiceDate.getMonth() + 1)));
    }

    const change = (selectedDate) => {
        let newMonth,
            newYear;

        /* 
        * Important!
        * Copy the filter properties to a new object so that the 
        * context value actually changes and not just updated
        */

        const updatedFilters = {};

        Object.assign(updatedFilters, filters);

        /* Important end! */

        newYear = selectedDate.getFullYear();
        newMonth = selectedDate.getMonth();

        updatedFilters.invoiceDate.setFullYear(newYear);
        updatedFilters.invoiceDate.setMonth(newMonth);

        setFilters(updatedFilters);
        setInvoiceDate(updatedFilters.invoiceDate);
    }

    const createMonths = (currentList, when, i = 1) => {
        const months = Array.from(Array(i).keys());

        let next = new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0));

        return months.reduce(list => {
            let month = next.getMonth(),
                monthName;

            switch (when) {
                case "past":
                    next.setMonth(month - 1);
                    break;
                case "future":
                    next.setMonth(month + 1);
                    break;
                case "now":
                    next.setMonth(month);
                    break;
                default:
                    break;
            }

            monthName = next.toLocaleString('default', { month: 'long', year: 'numeric' });

            list.push(<option key={monthName} defaultValue={when === "now"} value={new Date(next)}>{monthName}</option>);

            return list;
        }, currentList);
    }

    const monthList = () => {
        // Create previous n months
        const past = createMonths([], "past", limit);

        // Reverse the list so previous months come in order
        past.reverse();

        // Add the current month
        const now = createMonths(past, "now");

        // Create next n months
        return createMonths(now, "future", limit);
    }

    return (
        <ButtonGroup color="primary" aria-label="Räkning månad">
            <IconButton
                onClick={() => previous()}
            >
                {getIcon("LeftArrow")}
            </IconButton>

            <TextField
                id="invoiceDate"
                select
                value={invoiceDate}
                onChange={(e) => change(new Date(e.target.value))}
                SelectProps={{
                    native: true,
                }}
                InputProps={{
                    classes: {
                        root: classes.controlSelector,
                        notchedOutline: classes.notchedOutline
                    },
                }}
            >
                {monthList()}
            </TextField>

            <IconButton
                onClick={() => next()}
            >
                {getIcon("RightArrow")}
            </IconButton>
        </ButtonGroup>
    )
}

export default Selector;