import { makeStyles } from "@material-ui/core/styles";
import banner from 'assets/img/banners/fakturor.jpg';

const useStyles = makeStyles(theme => {
    return {
        pageBanner: {
            backgroundImage: `url(${banner})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        },
        invoiceForm: {
            display: "flex",
            justifyContent: "center",
        },
        invoiceFormGroup: {
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
            '& > *': {
                margin: theme.spacing(1, 0),
            },
            [theme.breakpoints.up('sm')]: {
                flexDirection: "row",
                '& > *': {
                    margin: theme.spacing(1),
                },
            }
        },
        invoiceInput: {
            width: "100%",
            color: theme.palette.formInput.main,
            backgroundColor: theme.palette.formInput.background,
            '&:focus': {
                borderRadius: 4,
                borderColor: '#80bdff',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
            },
            [theme.breakpoints.up('sm')]: {
                width: "12rem",
            }
        },
        invoiceButton: {
            width: "100%",
            height: "auto",
            borderRadius: 0,
            [theme.breakpoints.up('sm')]: {
                width: "9.6rem",
                borderRadius: 4,
            }
        },
    }
});

export default useStyles;