/*
 * Overview
 */

import React from 'react';
import { useQuery } from '@apollo/client';
import { OVERVIEW } from 'models/overview/overview';
import { useLoadingEffect } from 'components/loading/Loading';
import PersonCard from 'components/personCard/PersonCard';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './Styles';

const Pay = () => {
    const title = "Översikt";
    const classes = useStyles();

    /*---- Data query start ----*/
    const { loading, error, data } = useQuery(OVERVIEW, { fetchPolicy: "cache-and-network" });

    useLoadingEffect(loading);

    error && console.log(`Error! ${error.message}`);
    /*---- Data query end ----*/

    return (
        <Grid container className="page-container">
            <Grid item xs={12} className={`banner ${classes.pageBanner}`}>
                <Typography variant="h1">{title}</Typography>
            </Grid>

            <Grid container spacing={4} className="page">
                {data && data.users.map((person, p) => {
                    return person.bills.length > 0 && (
                        <Grid key={`person-${p}`} item xs={12} md={6} xl={3}>
                            <PersonCard person={person} />
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    )
}

export default Pay;