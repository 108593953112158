/*
 * LeftLinks
 */

import React from 'react';
import { useAuthToken } from "config/auth";
import { Typography, Tooltip, IconButton, ButtonGroup } from '@material-ui/core';
import { uri } from "config/Client";
import getIcon from 'models/icon/Icon';

const Links = () => {
    const [authToken] = useAuthToken();

    const goTo = (where) => {
        return window.open(where, "_blank");
    }

    const call = (where) => {
        fetch(where, { headers: { 'authorization': `Bearer ${authToken}` } })
        .then(() => window.location.reload());
    };

    return (
        <ButtonGroup color="primary" aria-label="Filter Switches">
            <Tooltip
                title={<Typography variant="body1">Till Adminsidan</Typography>}
            >
                <IconButton
                    onClick={() => goTo(`${uri}/admin`)}
                >
                    {getIcon("AuthLink")}
                </IconButton>
            </Tooltip>

            <Tooltip
                title={<Typography variant="body1">Omgenerera räkningar</Typography>}
            >
                <IconButton
                    onClick={() => call(`${uri}/bills/generate`)}
                >
                    {getIcon("Generate")}
                </IconButton>
            </Tooltip>
        </ButtonGroup>
    )
}

export default Links;