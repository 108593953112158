/*
 * Graphql - Bills
 */

import { gql, useMutation } from '@apollo/client';

export const BILLS = gql`
    query getBills {
        bills {
            id
            name
            company {
                logo {
                    formats
                }
            }
            payday
            invoices {
                id
                amount
                deadline
                paid
            }
            users {
                firstname
                picture {
                    formats
                }
            }
            payment_type {
                __typename
            }
        }
    }
`;

export const UPDATE_INVOICE = gql`
    mutation updateInvoice(
        $id: ID!,
        $invoices: [editComponentInvoicesInvoiceInput!]!
    ) {
    updateBill(input: {
        where: { id: $id }
        data: {
            invoices: $invoices
        }
    }) {
        bill {
            id
            invoices {
                id
                amount
                deadline
                paid
            }
        }
    }
}
`;

export const useInvoiceMutation = () => {
    const [setInvoice] = useMutation(UPDATE_INVOICE);

    const updateInvoices = (id, invoices, fastUpdate = false) => {
        invoices = invoices.map(invoice => {
            "__typename" in invoice && delete invoice.__typename;

            return invoice;
        });

        if (fastUpdate) {
            return setInvoice({
                variables: { id, invoices },
                optimisticResponse: {
                    __typename: "Mutation",
                    updateBill: {
                        bill: { id, invoices }
                    }
                },
                update: (cache, { data: { updateBill } }) => {
                    // Read the cache query.
                    const data = cache.readQuery({ query: BILLS }),
                          updatedData = JSON.parse(JSON.stringify(data));

                    updatedData.bills.map(bill => bill.id === updateBill.bill.id && (bill.invoices = updateBill.bill.invoices));

                    // Write the new data to the cache
                    cache.writeQuery({
                        query: BILLS,
                        data: updatedData
                    });
                }
            });
        } else {
            return setInvoice({
                variables: { id, invoices }
            });
        }
    };

    return [updateInvoices];
};