/*
 * RightSwitches
 */

import React from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import { useFilter } from "components/filter/FilterInvoice";
import getIcon from 'models/icon/Icon';

const Switches = () => {
    const filterContext = useFilter(),
          filters = filterContext.filters,
          setFilters = filterContext.setFilters;

    const togglePaid = () => {
        const updatedFilters = {};

        filters.paid = !filters.paid;

        Object.assign(updatedFilters, filters);
        
        setFilters(updatedFilters);
    }

    return (
        <ButtonGroup aria-label="Filter Switches">
            <Button
                color="primary"
                variant="text"
                onClick={togglePaid}
                endIcon={filters.paid ? getIcon("ToggleOn") : getIcon("ToggleOff")}
            >
                Betalda
            </Button>
        </ButtonGroup>
    )
}

export default Switches;