/*
 * PersonCard
 */

import React, { useState, Fragment } from 'react';
import getIcon from 'models/icon/Icon';
import { useFilter, FilterInvoice } from "components/filter/FilterInvoice";
import { uri } from "config/Client";
import utils from 'models/utils/utils';
import clsx from 'clsx';
import { Box, Typography, Card, CardHeader, Tooltip, CardContent, CardActions,
        Avatar, IconButton, Collapse, Table, TableBody, TableCell, TableContainer,
        TableHead, TableRow } from '@material-ui/core';
import useStyles from './Styles';

const currency = utils.currency,
      quantity = utils.quantity;

const calculatePayment = (amount, percent, bill) => {
    let monthly = bill.pay_monthly,
        frequency = monthly ? "Monthly" : bill.frequency,
        toPay;

    switch (frequency) {
        case "Monthly":
            toPay = (amount / 100) * percent;
            break;
        case "Quarterly":
            toPay = ((amount / 100) * percent) / 3;
            break;
        case "Half_yearly":
            toPay = ((amount / 100) * percent) / 6;
            break;
        case "Yearly":
            toPay = ((amount / 100) * percent) / 12;
            break;
        default:
            toPay = 0;
    }

    return toPay;
};

const BillInvoices = (person, bill) => {
    const [expandInvoice, setExpandInvoice] = useState(false),
          percent = bill.allocations.find(_ => _.user.id === person.id).allocation;
    
    let amount = {
        billTotal: 0.00,
        toPay: 0.00,
    }

    const invoices = (
        bill.invoices
            .filter(billIvoice => FilterInvoice(billIvoice))
            .map(invoice => {
                let invoiceToPay = calculatePayment(invoice.amount, percent, bill),
                    isOverdue = new Date() > new Date(invoice.deadline);

                amount.billTotal += invoice.amount;
                amount.toPay += invoiceToPay;

                return (
                    <Fragment>
                        <TableRow>
                            <TableCell>{bill.name}</TableCell>
                            <TableCell className={isOverdue && "overdue"}>{utils.getLocalDate(invoice.deadline).substring(0, 10)}</TableCell>
                            <TableCell>{currency(invoice.amount)}</TableCell>
                            <TableCell>{currency(invoiceToPay)}</TableCell>
                            <TableCell>
                                <IconButton color="primary" aria-label="expand bill" size="small" onClick={() => setExpandInvoice(!expandInvoice)}>
                                    {expandInvoice ? getIcon("keyboardUp") : getIcon("keyboardDown")}
                                </IconButton>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className="collapsed-cell" colSpan={5}>
                                <Collapse in={expandInvoice} timeout="auto" unmountOnExit>
                                    <Box margin={1}>
                                        <Typography variant="h6" gutterBottom component="div">
                                            Information
                                        </Typography>
                                    </Box>

                                    <Table className="collapsed-table" size="small" aria-label="information">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell width="50%">Konto</TableCell>
                                                <TableCell width="50%" align="right">{bill.account.name}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell width="50%">Frekvens</TableCell>
                                                <TableCell width="50%" align="right">{bill.frequency.replace("_", "-")}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell width="50%">Måndsvis</TableCell>
                                                <TableCell width="50%" align="right">{bill.pay_monthly ? "Ja" : "Nej"}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell width="50%">Prioritet</TableCell>
                                                <TableCell width="50%" align="right">{bill.priority}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>

                                    <Box margin={1}>
                                        <Typography variant="h6" gutterBottom component="div">
                                            Uppdelning
                                        </Typography>
                                    </Box>

                                    <Table className="collapsed-table" size="small" aria-label="Allocations">
                                        <TableBody>
                                            {bill.allocations.map((billAllocation, i) => {
                                                return (
                                                    <TableRow key={i}>
                                                        <TableCell width="50%">
                                                            {`${billAllocation.user.firstname} ${billAllocation.user.lastname}`}
                                                        </TableCell>
                                                        <TableCell width="50%" align="right">{billAllocation.allocation}%</TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>

                                    {bill.payment_type.length > 0 && bill.payment_type[0].__typename === "ComponentLoansLoan" &&
                                    <Fragment>
                                        <Box margin={1}>
                                            <Typography variant="h6" gutterBottom component="div">
                                                Lån
                                            </Typography>
                                        </Box>

                                        <Table className="collapsed-table" size="small" aria-label="Loan">
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell width="50%">Ränta</TableCell>
                                                    <TableCell width="50%" align="right">{`${bill.payment_type[0].interest}`}%</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell width="50%">Amotering</TableCell>
                                                    <TableCell width="50%" align="right">{`${bill.payment_type[0].amortization}`}:-</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell width="50%">Avgifter</TableCell>
                                                    <TableCell width="50%" align="right">{`${bill.payment_type[0].fees}`}:-</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Fragment>
                                    }
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    </Fragment>
                );
            })
    );

    return [invoices, amount];
}

const PersonCard = ({ person }) => {
    const classes = useStyles(),
          filterContext = useFilter(),
          avatarSrc = person.picture.formats.thumbnail.url,
          [openBillsTable, setOpenBillsTable] = useState(false),
          [expandSummary, setExpandSummary] = useState(false);
    
    let billsTotal = 0.00,
        totalToPay = 0.00,
        accounts = [],
        invoiceCount = 0;

    const bills = person.bills && (
        person.bills.map(bill => {
            const [invoices, amount] = BillInvoices(person, bill);

            billsTotal += amount.billTotal;
            invoiceCount += invoices.length;

            if (amount.toPay) {
                let accountName = bill.account.name,
                    pos = accounts.findIndex(acc => acc.name === accountName);

                if (pos < 0) {
                    accounts.push({ name: accountName, total: amount.toPay });
                } else {
                    accounts[pos].total += amount.toPay;
                }

                totalToPay += amount.toPay;
            }

            return invoices;
        })
    );

    const total = (
        <TableRow key={`${person.firstname}-total`}>
            <TableCell>Total</TableCell>
            <TableCell />
            <TableCell>{currency(billsTotal)}</TableCell>
            <TableCell>{currency(totalToPay)}</TableCell>
            <TableCell />
        </TableRow>
    );

    const summary = (
        <Table className="table">
            <TableBody>
                {!accounts.length
                    ? (
                        <TableRow key={`no-bills`}>
                            <TableCell>{filterContext.filters.paid ? "Inget betalt!" : "Inget kvar att betala!"}</TableCell>
                            <TableCell align="right">
                                <IconButton size="small" disabled>{getIcon("Done")}</IconButton>
                            </TableCell>
                        </TableRow>
                    ) : (
                        <Fragment>
                            <TableRow>
                                <TableCell align="left">{currency(totalToPay)}</TableCell>
                                <TableCell align="right">
                                    <IconButton color="secondary" size="small" aria-label="expand bill" onClick={() => setExpandSummary(!expandSummary)}>
                                        <Tooltip
                                            title={<Typography variant="body1">Visa detaljer</Typography>}
                                        >
                                            {expandSummary ? getIcon("keyboardUp") : getIcon("keyboardDown")}
                                        </Tooltip>
                                    </IconButton>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell className="collapsed-cell" colSpan={2}>
                                    <Collapse in={expandSummary} timeout="auto" unmountOnExit>
                                        <Box margin={1}>
                                            <Typography variant="h6" gutterBottom component="div">Kontofördelning</Typography>
                                        </Box>

                                        <TableContainer className="table-container-summary">
                                            <Table className="collapsed-table" size="small" aria-label="Information">
                                                <TableBody>
                                                    {accounts.map((account, a) => {
                                                        return (
                                                            <TableRow key={`account-${a}`}>
                                                                <TableCell width="75%" variant="head" component="th">{account.name}</TableCell>
                                                                <TableCell width="25%" align="right">{currency(account.total)}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <Box margin={1}>
                                            <Typography variant="h6" gutterBottom component="div">Statistik</Typography>
                                        </Box>

                                        <TableContainer className="table-container-summary">
                                            <Table className="collapsed-table" size="small" aria-label="Statistik">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell width="75%" variant="head" component="th">Antal räkningar</TableCell>
                                                        <TableCell width="25%" align="right">{quantity(invoiceCount)}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Collapse>
                                </TableCell>
                            </TableRow>
                        </Fragment>
                    )
                }
            </TableBody>
        </Table>
    );

    const expandBills = () => {
        setOpenBillsTable(!openBillsTable);
    };

    return (
        <Card className={classes.card}>
            <CardHeader
                className={classes.cardHeader}
                title={
                    <Typography variant="h4">
                        {`${person.firstname} ${person.lastname}`}
                    </Typography>
                }
                avatar={
                    <Tooltip
                        title={<Typography variant="body1">{person.firstname}</Typography>}
                    >
                        <Avatar
                            className={classes.avatar}
                            aria-label={person.firstname}
                            alt={person.firstname}
                            src={`${uri}${avatarSrc}`} 
                        />
                    </Tooltip>
                }
            />

            <CardContent className={classes.cardContent}>
                <Typography variant="h5" gutterBottom>Månadens utgifter</Typography>

                <TableContainer className="table-container-paper">{summary}</TableContainer>
            </CardContent>

            <Collapse className={classes.collapse} in={openBillsTable} timeout="auto" unmountOnExit>
                <TableContainer className="table-container">
                    <Table className="table" aria-label="Person invoice table">
                        <TableHead>
                            <TableRow>
                                <TableCell width="30%">Räkning</TableCell>
                                <TableCell width="25%">Föraller</TableCell>
                                <TableCell width="20%">Belopp</TableCell>
                                <TableCell width="20%">Betala</TableCell>
                                <TableCell width="5%">Öppna</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{[bills, total]}</TableBody>
                    </Table>
                </TableContainer>
            </Collapse>

            <CardActions className={classes.cardActions} disableSpacing>
                <IconButton
                    className={clsx(classes.expand, {
                        [classes.expandOpen]: openBillsTable,
                    })}
                    color="primary"
                    onClick={expandBills}
                    aria-expanded={openBillsTable}
                    aria-label="show more"
                    disabled={!totalToPay && true}
                >
                    <Tooltip
                        title={<Typography variant="body1">Visa räkningar</Typography>}
                    >
                        {getIcon("expand")}
                    </Tooltip>
                </IconButton>
            </CardActions>
        </Card>
    );
}

export default PersonCard;