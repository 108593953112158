/*
 * Bills
 */

import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useFilter, invoiceFilter } from "components/filter/FilterInvoice";
import { useLoadingEffect } from "components/loading/Loading";
import { BILLS, useInvoiceMutation } from 'models/bills/bills.js';
import { Grid, Tooltip, Typography, Button, ButtonGroup, TextField, Fab }  from '@material-ui/core';
import InvoiceCard from 'components/InvoiceCard/InvoiceCard';
import useStyles from './Styles';
import getIcon from 'models/icon/Icon';
import { uri } from "config/Client";
import utils from 'models/utils/utils';

const Bills = () => {
    const title = "Fakturor",
          [billOption, setBillOption] = useState("start"),
          [amount, setAmount] = useState(""),
          [selectedBill, setSelectedBill] = useState({}),
          [updateInvoices] = useInvoiceMutation(),
          filterContext = useFilter(),
          filters = filterContext.filters,
          setFilters = filterContext.setFilters,
          isActive = Object.keys(selectedBill).length,
          classes = useStyles();

    const newBill = () => {
        return window.open(
            `${uri}/admin/plugins/content-manager/collectionType/application::bill.bill/create`,
            "_blank"
        );
    };

    const getSelectedBill = (e) => {
        let billOption = e.target.value;

        try {
            let selectedBill = data.bills.find(bill => { return bill.id === billOption });

            setSelectedBill(selectedBill);
            setBillOption(billOption);
        } catch(error) {
            console.log(error);
        }
    };

    const createInvoice = (e) => {
        e.preventDefault();

        let id = selectedBill.id,
            billInvoices = JSON.parse(JSON.stringify(selectedBill.invoices)),
            invoiceDate = filters.invoiceDate,
            lastDay = utils.getLastDay(invoiceDate).getDate(),
            payday = selectedBill.payday > lastDay ? lastDay : selectedBill.payday,
            deadline = new Date(invoiceDate);

        deadline.setDate(payday);
        deadline = deadline.toISOString().substring(0, 10);

        billInvoices.push({ amount, deadline });

        updateInvoices(id, billInvoices)
        .then(() => {
            setSelectedBill({});
            setBillOption("start");
            setAmount("");
        })
        .catch(err => console.log(err));
    };

    // invoiceId should be an array
    const updateInvoicesPaid = (bill, invoiceId, paid) => {
        // Create new object to avoid readonly error
        const billInvoices = JSON.parse(JSON.stringify(bill.invoices));

        const updatedInvoices = billInvoices.map(invoice => {
            invoiceId.includes(invoice.id) && (invoice.paid = paid);

            return invoice;
        });
        
        // Update the bill invoices
        updateInvoices(bill.id, updatedInvoices, "fast")
            .catch(err => console.log(err));
    };

    const payAllInvoices = () => {
        // First create custom filter
        const overrideFilters = { 
            invoiceDate: filters.invoiceDate,
            paid: false
        };

        // Loop all bills and filter by selected date and where unpaid 
        data && data.bills.length && data.bills.map(bill => {
            let invoiceIdList = (
                bill.invoices
                    .filter(invoice => invoiceFilter(invoice, overrideFilters))
                    .reduce((list, invoice) => { list.push(invoice.id); return list }, [])
            );
            
            // Update the filtered bill invoices if there are any
            return invoiceIdList.length && updateInvoicesPaid(bill, invoiceIdList, new Date().toISOString().substring(0, 10));
        });

        overrideFilters.paid = true;

        setFilters(overrideFilters);
    };

    const deleteInvoice = (bill, invoiceId) => {
        let billInvoices = JSON.parse(JSON.stringify(bill.invoices)),
            updatedInvoices = billInvoices.filter(invoice => invoice.id !== invoiceId);

        updateInvoices(bill.id, updatedInvoices)
            .catch(err => console.log(err));
    };

    /*---- Data query start ----*/
    const { loading, error, data } = useQuery(BILLS);

    useLoadingEffect(loading);

    error && console.log(`Error! ${error.message}`);
    /*---- Data query end ----*/

    return (
        <Grid container className="page-container">
            <Grid item xs={12} className={`banner ${classes.pageBanner}`}>
                <Typography variant="h1">{title}</Typography>
            </Grid>

            <Grid container spacing={4} className="page">
                <Grid item xs={12}>
                    <form className={classes.invoiceForm} onSubmit={createInvoice}>
                        <ButtonGroup className={classes.invoiceFormGroup} aria-label="invoice form group">
                            <Button
                                className={classes.invoiceButton}
                                color="primary"
                                variant="contained"
                                startIcon={getIcon("Add")}
                                onClick={newBill}
                            >
                                Räkning
                            </Button>

                            <TextField
                                className={classes.invoiceInput}
                                id="invoice-bill"
                                select
                                label="Räkning"
                                value={billOption}
                                onChange={getSelectedBill}
                                SelectProps={{
                                    native: true,
                                }}
                                variant="filled"
                            >
                                <option key={`bill-01`} value={"start"} disabled>Välj här</option>

                                <optgroup label="Varierande">
                                    {data && data.bills.filter(bill => !bill.payment_type.length).map((bill, i) => (
                                        <option key={`bill-group1-${i}`} value={bill.id}>
                                            🧾 {bill.name}
                                        </option>
                                    ))}
                                </optgroup>

                                <optgroup label="Kalkylerade">
                                    {data && data.bills.filter(bill => bill.payment_type.length).map((bill, i) => (
                                        <option key={`bill-group2-${i}`} value={bill.id}>
                                            🧾 {bill.name}
                                        </option>
                                    ))}
                                </optgroup>
                            </TextField>

                            <TextField
                                className={classes.invoiceInput}
                                id="invoice-amount"
                                label="Belopp"
                                type="number"
                                inputProps={{ min: "1", step: "0.01" }}
                                value={amount}
                                onChange={(e) => setAmount(parseFloat(e.target.value))}
                                disabled={!isActive}
                                required
                                error={isNaN(amount)}
                                variant="filled"
                            />

                            <Button
                                className={classes.invoiceButton}
                                color="primary"
                                type="submit"
                                variant="contained"
                                startIcon={getIcon("Save")}
                                disabled={!isActive}
                            >
                                Spara
                            </Button>
                        </ButtonGroup>
                    </form>
                </Grid>
                
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        {data && data.bills.length > 0 && data.bills.map(bill => {
                            return (
                                bill.invoices
                                    .filter(invoice => invoiceFilter(invoice, filters))
                                    .map((invoice, i) => {
                                        return (
                                            <Grid key={i} item xs={12} sm={6} md={4} lg={3} xl={2}>
                                                <InvoiceCard
                                                    bill={bill} 
                                                    invoice={invoice}
                                                    manage={{ deleteInvoice, updateInvoicesPaid }}
                                                />
                                            </Grid>
                                        );
                                    })
                            )
                        })}
                    </Grid>

                    <Tooltip
                        title={<Typography variant="body1">Betala månadens räkningar</Typography>}
                    >
                        <Fab
                            color="primary"
                            aria-label="pay all"
                            onClick={payAllInvoices}
                        >
                            {getIcon("Done")}
                        </Fab>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Bills;
