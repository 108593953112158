import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => {
    return {
        card: {
            maxWidth: "100%",
            margin: "auto",
            backgroundColor: theme.palette.background.dark,
            color: theme.palette.primary.main,
            borderRadius: "1rem",
            [theme.breakpoints.up('sm')]: {
                maxWidth: 600,
                justifyContent: "center"
            }
        },
        cardHeader: {},
        avatar: {
            width: theme.spacing(10),
            height: theme.spacing(10),
        },
        cardContent: {
            "& .table-container-summary": {
                "& th": {
                    [theme.breakpoints.down('xs')]: {
                        width: "100%",
                        display: "block",
                    }
                },
                "& td": {
                    [theme.breakpoints.down('xs')]: {
                        width: "100%",
                        display: "block",
                        textAlign: "left"
                    },
                },
            }
        },
        collapse: {
            paddingLeft: "1rem",
            paddingRight: "1rem",
            "& .table-container": {
                marginTop: "1rem",
                marginBottom: "1rem"
            },
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        cardActions: {
            display: "flex",
            justifyContent: "flex-end",
            borderTop: `1px solid ${theme.palette.border.primary.main}`,
        },
    }
});

export default useStyles;