/*
 * InvoiceCard
 */

import React, { Fragment } from 'react';
import getIcon from 'models/icon/Icon';
import { uri } from "config/Client";
import utils from 'models/utils/utils';
import { Grid, Box, Tooltip, Typography, Card, CardHeader, CardMedia, CardContent, CardActions, Avatar, IconButton } from '@material-ui/core';
import useStyles from './Styles';

// Table helper
const InvoiceCard = ({ bill, invoice, manage }) => {
    const classes = useStyles();

    const avatars = bill.users.map((person, i) => {
        let src = person.picture.formats.thumbnail.url,
            alt = person.fristname;

         return (
             <Tooltip
                 title={
                     <Typography variant="body1">
                         {person.firstname}
                     </Typography>
                 }
             >
                <Avatar key={i} aria-label={person.firstname} alt={alt} src={`${uri}${src}`} />
            </Tooltip>
         );
    });
    
    return (
        <Card className={classes.card}>
            <CardHeader
                className={classes.cardHeader}
                title=
                {
                    <Fragment>
                        <Grid container>
                            <Grid item xs={10}>
                                <Grid container justify="flex-start">
                                    <Typography variant="h4">
                                        {bill.name}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={2}>
                                <Grid container justify="flex-end">
                                    <Tooltip
                                        title={
                                            <Typography variant="body1">
                                                {invoice.paid ? "Betald" : "Obetald"}
                                            </Typography>
                                        }
                                    >
                                        {getIcon(invoice.paid ? "Paid" : "NotPaid")}
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Typography variant="h6">
                            {utils.getLocalDate(invoice.deadline).substring(0, 10)}
                        </Typography>

                        <Box className={classes.avatar}>
                            {avatars}
                        </Box>
                    </Fragment>
                }
            />

            <CardMedia
                className={classes.media}
                image={`${uri}${bill.company.logo.formats.thumbnail.url}`}
                title={bill.name}
            />

            <CardContent className={classes.CardContent}>
                <Typography variant="h5">
                    {Number.isInteger(invoice.amount) ? invoice.amount : invoice.amount.toFixed(2)}:-
                </Typography>
            </CardContent>

            <CardActions className={classes.cardActions} disableSpacing>
                <Tooltip
                    title=
                    {
                        <Typography variant="body1">
                            Ta bort
                        </Typography>
                    }
                >
                    <IconButton
                        color="primary"
                        size="medium"
                        aria-label="delete"
                        onClick={() => manage.deleteInvoice(bill, invoice.id)}
                    >
                        {getIcon("Delete")}
                    </IconButton>
                </Tooltip>

                <Tooltip
                    title=
                    {
                        <Typography variant="body1">
                            Markera som {invoice.paid ? "obetald" : "betald"}
                        </Typography>
                    }
                >
                    <IconButton
                        color="primary"
                        size="medium"
                        aria-label="paid"
                        onClick={() => manage.updateInvoicesPaid(bill, [invoice.id], invoice.paid ? null : new Date().toISOString().substring(0, 10))}
                    >
                        {getIcon(invoice.paid ? "Cancel" : "Done")}
                    </IconButton>
                </Tooltip>
            </CardActions>
        </Card>
    );
}

export default InvoiceCard;