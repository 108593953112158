/*
 * Graphql - Overview
 */

import { gql } from '@apollo/client';

export const OVERVIEW = gql`
    query getUserBills {
        users {
            id
            firstname
            lastname
            accounts {
                name
                type
                number
                bank
            }
            picture {
                formats
            }
          	bills {
                name
                frequency
                pay_monthly
                priority
                account {
                    id
                    name
                }
                invoices {
                    id
                    amount
                    paid
                    deadline
                }
                allocations {
                    user {
                        id
                        firstname
                        lastname
                    }
                    allocation
                }
                payment_type {
                    __typename
                    ... on ComponentLoansLoan {
                        interest
                        amortization
                        fees
                    }
                }
            }
        }
        bills {
            name
            company {
                logo {
                    formats
                }
            }   
        }
    }
`;